import React from "react"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faPenNib } from "@fortawesome/free-solid-svg-icons"
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { faBuilding } from "@fortawesome/free-solid-svg-icons"

import ContactInfo from "../components/ContactInfo"
import "../styles/Contact.styl"

export default function Contact() {
  const { t, i18n } = useTranslation()

  function handleClick(e) {
    const validity = [
      document.getElementById("name"),
      document.getElementById("email"),
      document.getElementById("number"),
      document.getElementById("message"),
    ]
    validity.map(formElement => {
      if (!formElement.checkValidity()) {
        console.log(formElement.id)
      }
    })
  }

  function handleChange(formElement) {
    let element = formElement.target
    let elementTitle = element.parentNode.parentNode.children[0]

    if (element.value.length !== 0) {
      elementTitle.classList.add("--opacity")
    } else {
      elementTitle.classList.remove("--opacity")
    }
  }
  return (
    <>
      <form
        action="https://handle-form.now.sh"
        className="contact"
        method="POST"
      >
        <h1 className="form-title">{t("contact.title")}</h1>
        <div className="fields">
          <div className="name field__wrapper">
            <h4 className="field__title">
              {t("contact.name")} <br />
            </h4>
            <div className="field__border">
              <FontAwesomeIcon icon={faUser} className="field__icon" />
              <input
                type="text"
                name="name"
                id="name"
                placeholder={t("contact.name")}
                className="field"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="email field__wrapper">
            <h4 className="field__title">
              {t("contact.email")} <br />
            </h4>
            <div className="field__border">
              <FontAwesomeIcon icon={faEnvelope} className="field__icon" />
              <input
                type="email"
                name="email"
                id="email"
                placeholder={t("contact.email")}
                className="field"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="number field__wrapper">
            <h4 className="field__title">
              {t("contact.number")} <br />
            </h4>
            <div className="field__border">
              <FontAwesomeIcon icon={faPhoneAlt} className="field__icon" />
              <input
                type="tel"
                name="number"
                id="number"
                placeholder={t("contact.number")}
                className="field"
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="bussiness field__wrapper">
            <h4 className="field__title">
              {t("contact.company")} <br />
            </h4>
            <div className="field__border">
              <FontAwesomeIcon icon={faBuilding} className="field__icon" />
              <input
                type="text"
                name="company"
                id="company"
                placeholder={t("contact.company")}
                className="field"
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="message field__wrapper">
            <h4 className="field__title">
              {t("contact.message")} <br />
            </h4>
            <div className="field__border field__border--icon-top field__message--size">
              <FontAwesomeIcon icon={faPenNib} className="field__icon" />
              <textarea
                type="text"
                name="message"
                id="message"
                placeholder={t("contact.message")}
                className="field field__message field__message--size "
                onChange={handleChange}
                required
              ></textarea>
            </div>
          </div>
        </div>
        <button
          onClick={handleClick}
          type="submit"
          className="button --positiveBlue"
        >
          {t("contact.send")}
        </button>
      </form>
      <ContactInfo />
    </>
  )
}
