import React from "react"
import "../styles/ContactInfo.styl"
import { useTranslation } from "react-i18next"

export default function Map() {
  const { t } = useTranslation()
  return (
    <div className="info__wrapper">
      <div className="info__data">
        <h1 className="info__title">{t("map.title")}</h1>
        <address className="info__address">
          Farkcorp S.A. de C.V. Av Luis Lusati, Mexico Nuevo,
          <br /> 52966 Cd López Mateos, Méx.
        </address>
        <div className="info__contact">
          Email:
          <p>contacto@farkcorp.com</p>
          <br />
          Teléfonos:
          <p>
            <br />
            55 53 41 42 42
            <br />
            55 53 41 42 32
          </p>
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d939.8522253277836!2d-99.26744317077407!3d19.5669749991755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTnCsDM0JzAxLjEiTiA5OcKwMTYnMDAuOCJX!5e0!3m2!1ses-419!2smx!4v1580803246884!5m2!1ses-419!2smx"
        className="info__map"
        frameborder="0"
        allowfullscreen=""
      ></iframe>
    </div>
  )
}
